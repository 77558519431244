import React from "react"

export default () => {
  return (
    <div className="w-full py-8 mx-auto ">
      <div className="container flex flex-col items-center justify-center mx-auto font-thin">
        <div>
          <span className="ssspin">&copy;</span> Jeff Barg 2021
        </div>
      </div>
    </div>
  )
}
